import { mediaService } from 'src/api/services/media';
import style from './MediaLibraryList.module.scss';
import { classNames } from 'primereact/utils';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { getAxiosError } from 'src/utils/get-axios-error';
import { toast } from 'src/utils/toast';
import { TOAST_STATUS } from 'src/constants/toast-status';
import { useEffect, useRef, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Link } from 'react-router-dom';

interface Props {
    numberOfUsed: number;
    uuid: string;
}

const usageDetailMetaData = {
    person: { route: ROUTE_CONSTANTS.PERSONS.PEOPLE.ROOT.ABSOLUTE, label: 'Person' },
    news_source: { route: ROUTE_CONSTANTS.SETTINGS.NEWS_SOURCE.ROOT.ABSOLUTE, label: 'News Source' },
};

const NumberOfUsed: React.FC<Props> = ({ numberOfUsed, uuid }) => {
    const [usageDetail, setUserDetail] = useState<null | { label: string; value: string | number; route: string }[]>();
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const timeoutId = useRef<number | null>(null);

    const onMouseEnter = () => {
        if (timeoutId.current) clearTimeout(timeoutId.current)
        setShowTooltip(true);
    };

    const onMouseLeave = () => setShowTooltip(false);

    useEffect(() => {
        if (usageDetail && !showTooltip) {
            setShowTooltip(true);

            timeoutId.current = window.setTimeout(() => {
                setShowTooltip(false);
            }, 3000);

            return () => {
                if (timeoutId.current) clearTimeout(timeoutId.current);
            };
        }
    }, [usageDetail]);

    const onClickUsage = async () => {
        try {
            setUserDetail(null);
            const response = await mediaService.usageDetail(uuid);
            const { private_companies, public_companies, independent_entities } = response.data.data;
            const usageDetailData = [
                ...(private_companies.ids
                    ? [
                          {
                              value: private_companies.count,
                              label: 'Private Company',
                              route:
                                  ROUTE_CONSTANTS.COMPANIES.TYPE.LIST_BY_COMPANY_TYPE(COMPANY_TYPE_ROUTE.PRIVATE).ABSOLUTE +
                                  '?' +
                                  private_companies.ids.map((id) => `ids[]=${id}`).join('&'),
                          },
                      ]
                    : []),
                ...(public_companies.ids
                    ? [
                          {
                              value: public_companies.count,
                              label: 'Public Company',
                              route:
                                  ROUTE_CONSTANTS.COMPANIES.TYPE.LIST_BY_COMPANY_TYPE(COMPANY_TYPE_ROUTE.PUBLIC).ABSOLUTE +
                                  '?' +
                                  public_companies.ids.map((id) => `ids[]=${id}`).join('&'),
                          },
                      ]
                    : []),
                ...(independent_entities.length
                    ? independent_entities.map((item) => ({
                          value: item.count,
                          label: usageDetailMetaData[item.reference].label,
                          route: usageDetailMetaData[item.reference].route + '?' + item.ids.map((id) => `ids[]=${id}`).join('&'),
                      }))
                    : []),
            ];

            setUserDetail(usageDetailData);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.message || 'Server Error';
            toast.fire({
                icon: TOAST_STATUS.ERROR,
                title: message,
            });
        }
    };

    return (
        <span
            className={classNames(style.numberOfUsed, !usageDetail && numberOfUsed !== 0 ? style.numberOfUsedClickable : '')}
            onClick={usageDetail || numberOfUsed === 0 ? undefined : onClickUsage}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {numberOfUsed}
            {usageDetail === null ? <ProgressSpinner style={{ width: 20, height: 20 }} strokeWidth={'2'} animationDuration=".5s" /> : null}
            {usageDetail ? (
                <div className={classNames(style.tooltip, showTooltip ? style.tooltipActive : '')}>
                    {usageDetail?.map((item, index) => (
                        <span key={index}>
                            {item.label}:
                            <Link target="_blank" to={item.route}>
                                {item.value}
                            </Link>
                        </span>
                    ))}
                </div>
            ) : null}
        </span>
    );
};

export default NumberOfUsed;
