import { ITableColumnData } from 'src/api/types/table';

export const mediaDataColumnsRender: ITableColumnData[] = [
    {
        field: 'original_name',
        sortable: true,
        header: 'Name',
        showCurrency: false,
    },
    {
        field: 'media_type',
        sortable: false,
        header: 'Media Type',
        showCurrency: false,
    },
    {
        field: 'number_of_used',
        sortable: false,
        header: 'Number Of Used',
        showCurrency: false,
    },
    {
        field: 'created_at',
        sortable: true,
        header: 'Created At',
        showCurrency: false,
    },
    {
        field: 'created_by',
        sortable: false,
        header: 'Created By',
        showCurrency: false,
    },
    {
        field: 'updated_by',
        sortable: false,
        header: 'Updated By',
        showCurrency: false,
    },
    {
        field: 'updated_at',
        sortable: true,
        header: 'Updated At',
        showCurrency: false,
    },
];
