import React, { Dispatch, SetStateAction, useState } from 'react';
import DashboardTitle from 'src/components/App/DashboardTitle';
import Table from 'src/components/App/Table/index';
import Button from 'src/components/Kit/Button';
import { Helmet } from 'react-helmet-async';
import { Column } from 'primereact/column';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import DeleteIcon from 'src/assets/Icons/DeleteIcon';
import { modalActions } from 'src/context/modals';
import { MODAL_TYPES } from 'src/types/modals';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { mediaService } from '../../../../api/services/media';
import { IFileStoreResponse, IFileStoreTableResponse } from '../../../../api/types/file';
import { mediaDataColumnsRender } from './mediaDataColumnsRender';
import { mediaDataCellRender } from './mediaDataCellRender';
import { IDataTablePayload } from '../../../../api/types/table';
import { toast } from '../../../../utils/toast';
import { TOAST_STATUS } from '../../../../constants/toast-status';
import { getAxiosError } from '../../../../utils/get-axios-error';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import { COMPANY_TYPE_ROUTE } from 'src/pages/Company/Add/enums/company-type-route';
import { Link } from 'react-router-dom';
import style from './MediaLibraryList.module.scss';
import { classNames } from 'primereact/utils';
import { ProgressSpinner } from 'primereact/progressspinner';
import NumberOfUsed from './NumberOfUsed';

const service = async (queryParams?: IDataTablePayload) =>
    mediaService.getMedias(queryParams).then((res) => {
        return {
            ...res,
            data: {
                ...res.data,
                meta: {
                    ...res.data.meta,
                    columns: mediaDataColumnsRender,
                },
            },
        } as any;
    });

export interface IUsageDetail {
    [key: number]: null | { label: string; value: string | number; route: string }[];
}

const MediaLibraryList: React.FC = () => {
    
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const handleRemove = (rowData: IFileStoreResponse, setTableData: Dispatch<SetStateAction<IFileStoreResponse[]>>) => {
        const handleRemoveItem = async () => {
            try {
                const deleteResult = await mediaService.getSignedDeleteUrl(rowData.uuid.toString());
                await mediaService.invokeSignedDeleteUrl(deleteResult.data.data);
                setTableData((tableData: IFileStoreResponse[]) => tableData.filter((item: IFileStoreResponse) => item.id !== rowData.id));
                modalActions.clearModals();

                toast.fire({
                    icon: TOAST_STATUS.SUCCESS,
                    title: deleteResult.data.message,
                });
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.message || 'Server Error';
                toast.fire({
                    icon: TOAST_STATUS.ERROR,
                    title: message,
                });
            }
        };

        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: 'Delete Confirm',
            text: 'Are you sure to delete this Media?',
            handleAction: handleRemoveItem,
        });
    };

    const suffixCols = (data: IFileStoreResponse[], setTableData: Dispatch<SetStateAction<IFileStoreResponse[]>>) => {
        return (
            <Column
                key="Action"
                header={'Actions'}
                style={{
                    width: 60,
                }}
                body={(rowData: IFileStoreResponse) => (
                    <>
                        {checkPermissionAccess(PERMISSION_TYPES.SETTING_MEDIA_LIBRARY_DELETE) && (
                            <Button
                                color="secondary"
                                disabled={!rowData.can_delete_media}
                                iconOnly
                                size="m"
                                onClick={() => handleRemove(rowData, setTableData)}
                            >
                                <DeleteIcon />
                            </Button>
                        )}
                    </>
                )}
            />
        );
    };

    const cellRender = {
        ...mediaDataCellRender,
        number_of_used: (data: IFileStoreTableResponse) => <NumberOfUsed numberOfUsed={data.number_of_used} uuid={data.uuid} />,
    };

    return (
        <>
            <Helmet>
                <title>Teaser | Media Library</title>
            </Helmet>
            <DashboardTitle title={'Media Library'} />
            <DashboardContentWrapper>
                {checkPermissionAccess(PERMISSION_TYPES.SETTING_MEDIA_LIBRARY_LIST_VIEW) && (
                    <Table getTableData={service} cellRender={cellRender as any} suffixCols={suffixCols} />
                )}
            </DashboardContentWrapper>
        </>
    );
};

export default MediaLibraryList;
