import { IFileStoreTableResponse } from '../../../../api/types/file';
import pdfIcon from 'src/assets/Images/pdf-icon.svg';
import { truncateString } from '../../../../utils/string-truncator';
import { timestampToDateFormat } from '../../../../utils/timestamp-to-date-format';
import styles from './MediaLibraryList.module.scss';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { FILE_UPLOAD_TYPE } from '../../../../components/Kit/FileDialog/constants';
import { mediaService } from '../../../../api/services/media';
import { toast } from '../../../../utils/toast';

const onClickDownload = async (uuid: string) => {
    try {
        const signedDownloadResponse = await mediaService.getSignedDownloadFileUrl(uuid);
        const signedDownloadUrl = signedDownloadResponse.data.data;
        window.open(signedDownloadUrl, '_blank');
    } catch (e) {
        toast.fire({
            icon: 'error',
            title: 'Error in deleting',
        });
    }
};

export const mediaDataCellRender = {
    original_name: (data: IFileStoreTableResponse) => (
        <>
            <div style={{ width: 300 }}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 12 }}>
                    {data.is_image ? (
                        <img
                            onClick={() => onClickDownload(data.uuid)}
                            width={42}
                            height={42}
                            style={{ borderRadius: '8px', cursor: 'pointer' }}
                            key={data.id}
                            src={data.url}
                            alt={data.original_name}
                        />
                    ) : (
                        <img onClick={() => onClickDownload(data.uuid)} src={pdfIcon} alt="pdf" width={42} height={42} style={{ cursor: 'pointer' }} />
                    )}
                    <div className={styles.mediaWrapper}>
                        <span className={styles.mediaWrapperTitle}>{truncateString(data.original_name, 60)}</span>
                        <span className={classNames(styles.mediaWrapperDesc, `media-description_${data.id}`)}>
                            {truncateString(data.description || '', 45)}
                        </span>
                    </div>
                </div>
            </div>
            {data.description?.length > 45 && (
                <Tooltip target={`.media-description_${data.id}`} hideDelay={100} content={data.description} position="mouse" showDelay={250} />
            )}
        </>
    ),
    media_type: (data: IFileStoreTableResponse) => <>{data.media_type === FILE_UPLOAD_TYPE.IMAGE ? 'Image' : 'File'}</>,
    updated_at: (data: IFileStoreTableResponse) => <>{timestampToDateFormat(data.updated_at)}</>,
    created_at: (data: IFileStoreTableResponse) => <>{timestampToDateFormat(data.created_at)}</>,
    created_by: (data: IFileStoreTableResponse) => <>{data.created_by}</>,
    updated_by: (data: IFileStoreTableResponse) => <>{data.updated_by}</>,
};
