import React, { Dispatch, ReactNode, SetStateAction, useEffect, useReducer, useState } from 'react';
import { DataTable, DataTableMultiSortMetaType, DataTablePFSEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { ROW_PER_PAGE_SIZES } from '../../../constants/pagination';
import InitLoading from '../Loading/InitLoading';
import { Paginator, PaginatorPageState } from 'primereact/paginator';
import CheckboxFilter from './Filter/CheckboxFilter';
import Button from 'src/components/Kit/Button';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import useQueryParams from 'src/hooks/useQueryParams';
import { filterItemComponent, tableOrderName, tableOrderSymbol } from 'src/constants/table';
import {
    IBodyListFiltersParams,
    IBodyListSortParams,
    IDataTablePayload,
    IDataTableResponse,
    ITableBaseFilterData,
    ITableCustomColumnData,
    ITableColumnData,
    FilterSchemaChangeEvent,
    // ITableFilterData,
} from 'src/api/types/table';
import { classNames } from 'primereact/utils';
import DotsIcon from 'src/assets/Icons/DotsIcon';
import FilterIcon from 'src/assets/Icons/FilterIcon';
import CustomizeIcon from 'src/assets/Icons/CustomizeIcon';
import { useDragScroll } from 'src/hooks/useDragScroll';
import { FILTER_TYPES, SORT_ORDER_NAME } from 'src/enums/table';
import { AxiosResponse } from 'axios';
import { ISearchableDropdownConfig } from 'src/types/searchable-dropdown';
import style from './Table.module.scss';

export interface IProps<T> {
    getTableData: (queryParams?: IDataTablePayload) => Promise<AxiosResponse<IDataTableResponse<T>>> | AxiosResponse<IDataTableResponse<T>>;
    suffixCols?: (data: T[], setTableData: Dispatch<SetStateAction<T[]>>) => JSX.Element;
    cellRender?: { [key: string]: (data: T & { setFields: Dispatch<SetStateAction<T[]>> }) => JSX.Element };
    filterPermission?: string;
}

function Table<T>({ getTableData, suffixCols, cellRender = {}, filterPermission = '' }: IProps<T>) {
    const { setParam, getParams, removeParam, getParam, getArrayParam } = useQueryParams();
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const [htmlRef, handlers, isScrolling] = useDragScroll<HTMLDivElement>({ sensitivity: 1 });

    const [initLoading, setInitLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [tableData, setTableData] = useState<T[]>([]);
    const [tableFilters, setTableFilters] = useState<ITableBaseFilterData<FILTER_TYPES>[]>([]);
    const [columns, setColumns] = useState<ITableColumnData[]>([]);
    const [customColumns, setCustomColumns] = useState<ITableCustomColumnData[]>([]);
    const [totalRows, setTotalRows] = useState<number>();
    const [pageReport, setPageReport] = useState<ReactNode>();
    const [hasPaginator, setHasPaginator] = useState<boolean>();
    const [showFilters, toggleShowFilters] = useReducer((prev) => !prev, false);
    const [showCustomize, toggleShowCustomize] = useReducer((prev) => !prev, false);

    // handle permissions
    const hasFilterPermissionAccess = checkPermissionAccess(filterPermission);
    const hasFilterPermission = Boolean(filterPermission.length === 0) || hasFilterPermissionAccess;

    // get query Params
    const filtersQueryParams = getParams(['page', 'per_page', 'sort', 'ids[]']);
    const pageNumberQueryParam = getParam('page');
    const perPageQueryParam = getParam('per_page');
    const sortQueryParam = getParam('sort');
    const idsQueryParam = getArrayParam('ids[]');

    // query params adapters
    const parsedSortQueryParam = sortQueryParam?.length ? sortQueryParam.split(' ') : undefined;
    const sortQueryParamArray = parsedSortQueryParam
        ? [{ field: parsedSortQueryParam[0], order: tableOrderSymbol[parsedSortQueryParam[1] as SORT_ORDER_NAME] }]
        : [];

    const hasFilterQueryParams = Object.keys(filtersQueryParams).length;
    const filtersQueryParamsAdapted = hasFilterQueryParams
        ? (Object.entries(filtersQueryParams).map(([field, value]) => ({ field, value })) as IBodyListFiltersParams[])
        : [];

    // handle init state
    const initSearchParamsState = {
        ...(idsQueryParam.length ? {ids: idsQueryParam} : {}),
        ...((hasFilterQueryParams || pageNumberQueryParam?.length || perPageQueryParam?.length || sortQueryParam?.length) && {
            search: {
                ...(hasFilterQueryParams && { filters: filtersQueryParamsAdapted }),
                ...(perPageQueryParam?.length && { per_page: Number(perPageQueryParam) }),
                ...(sortQueryParam?.length &&
                    parsedSortQueryParam && {
                        sorts: sortQueryParamArray,
                    }),
            },
            ...(pageNumberQueryParam?.length && { page: Number(pageNumberQueryParam) }),
        }),
    };

    const [searchParams, setSearchParams] = useState<IDataTablePayload>(initSearchParamsState);
    const [firstRow, setFirstRow] = useState<number>();
    const [multiSortMeta, setMultiSortMeta] = useState<DataTableMultiSortMetaType>(sortQueryParamArray as DataTableMultiSortMetaType);
    const [disableSubmitFilterButton, setDisableSubmitFilterButton] = useState<boolean>(Boolean(hasFilterQueryParams));
    const [disableClearFilterButton, setDisableClearFilterButton] = useState<boolean>(!Boolean(hasFilterQueryParams));

    const fetchData = async (fetchParam?: IDataTablePayload) => {
        const params = fetchParam || searchParams;
        const tableData = typeof getTableData === 'function' ? await getTableData(params) : getTableData;

        if (tableData) {
            const {
                data: { data, meta },
            } = tableData;
            setTableData(data);
            setSearchParams({ ...params, search: { ...params?.search, per_page: meta.pagination?.per_page } });
            setTableFilters(meta.filters);
            setColumns(meta.columns);
            setCustomColumns(
                meta.columns.length
                    ? meta.columns.filter(({ field }) => meta.customize.includes(field)).map((customColumnItem) => ({ ...customColumnItem, active: true }))
                    : []
            );
            setInitLoading(false);
            setTableLoading(false);
            setTotalRows(meta.pagination.total);
            setFirstRow((meta.pagination.current_page - 1) * meta.pagination.per_page);
            setPageReport(
                <span>
                    {meta.pagination.total > 0
                        ? `Showing ${meta.pagination.per_page * (meta.pagination.current_page - 1) + 1} - ${
                              meta.pagination.per_page * meta.pagination.current_page < meta.pagination.total
                                  ? meta.pagination.per_page * meta.pagination.current_page
                                  : meta.pagination.total
                          } of ${meta.pagination.total} Results`
                        : 'No results found'}
                </span>
            );
            setHasPaginator(meta.pagination.total > meta.pagination.per_page);
        }
    };

    const handleToggleFilter = () => {
        toggleShowFilters();
        showCustomize && toggleShowCustomize();
    };

    const handleToggleCustomize = () => {
        toggleShowCustomize();
        showFilters && toggleShowFilters();
    };

    const handleToggleColumn = (field: string) => {
        setCustomColumns((prev) => prev.map((item) => (item.field === field ? { ...item, active: !item.active } : item)));
    };

    const handleTablePerPage = (perPage: number) => {
        setTableLoading(true);
        const newSearchParamsState = { ...searchParams, search: { ...searchParams.search, per_page: perPage }, page: 1 };
        setParam('per_page', String(perPage));
        setParam('page', '1', true);
        setSearchParams(newSearchParamsState);
        fetchData(newSearchParamsState);
    };

    const onPageChange = (e: PaginatorPageState) => {
        // TODO: There is a problem here, and that is that when searchParams state is changed, this function is called once
        setTableLoading(true);
        const currentPage = e.page + 1;
        const newSearchParamsState = { ...searchParams, page: currentPage };
        setParam('page', String(currentPage), true);
        setFirstRow(e.first);
        setSearchParams(newSearchParamsState);
        fetchData(newSearchParamsState);
    };

    const onSort = (e: DataTablePFSEvent) => {
        setTableLoading(true);
        const newSearchParamsState = { ...searchParams, search: { ...searchParams.search, sorts: e.multiSortMeta as IBodyListSortParams[] } };
        if (e.multiSortMeta?.length) {
            const sortQueryParams = e.multiSortMeta.map(({ field, order }) => (order ? `${field} ${tableOrderName[order]}` : '')).join(',');
            setParam('sort', sortQueryParams, true);
        } else {
            removeParam('sort', true);
        }
        setMultiSortMeta(e.multiSortMeta);
        setSearchParams(newSearchParamsState);
        fetchData(newSearchParamsState);
    };

    const renderFilterItem = <T extends FILTER_TYPES, D extends ITableBaseFilterData<T>>(type: T, filterItem: D) => {
        const defaultValue = searchParams?.search?.filters?.find((filter) => filter.field === filterItem.name)?.value || '';

        if (defaultValue && type === FILTER_TYPES.SELECT) {
            (filterItem.config as ISearchableDropdownConfig).params = { filter: [defaultValue] };
        }

        const filterItemProps = {
            defaultValue,
            name: filterItem.name,
            label: filterItem.label,
            onChange: (e: FilterSchemaChangeEvent[T]) => onFilter(e.target.name, e.target.value),
            ...((type === FILTER_TYPES.SELECT || type === FILTER_TYPES.DATE) && ({ config: filterItem.config } as any)),
        };

        const RenderComponent = filterItemComponent[type];
        return <RenderComponent {...filterItemProps} />;
    };

    const onFilter = (field: string, value: number | string, submit: boolean = false) => {
        submit && setTableLoading(true);
        const currentFilter = { field, value: String(value) };
        let newSearchParamsState: IDataTablePayload;
        if (searchParams?.search?.filters?.length) {
            const filters = [...searchParams.search.filters];
            const filterIndex = filters.findIndex((e) => e.field === field);
            if (String(value).length) {
                if (filterIndex > -1) {
                    filters[filterIndex] = currentFilter;
                } else {
                    filters.push(currentFilter);
                }
            } else {
                filters.splice(filterIndex, 1);
            }
            newSearchParamsState = { ...searchParams, search: { ...searchParams?.search, filters } };
        } else {
            newSearchParamsState = { ...searchParams, search: { ...searchParams?.search, filters: [currentFilter] } };
        }

        setSearchParams(newSearchParamsState);
        if (submit) {
            removeParam(field);
            setParam(field, String(value), true);
            fetchData(newSearchParamsState);
            setDisableClearFilterButton(!newSearchParamsState.search?.filters?.length);
            setDisableSubmitFilterButton(!!newSearchParamsState.search?.filters?.length);
        }
    };

    const handleSubmitFilters = () => {
        setTableLoading(true);
        const { page, ...newSearchParamsState } = searchParams;

        if (newSearchParamsState?.search?.filters?.length) {
            const filterKeys = newSearchParamsState.search.filters.map((filterItem) => filterItem.field);
            removeParam(filterKeys);
            newSearchParamsState.search.filters.forEach(({ field, value }) => {
                setParam(field, value, true);
            });
            removeParam('page', true);
            setSearchParams(newSearchParamsState);
        }

        fetchData(newSearchParamsState);
        setDisableClearFilterButton(!newSearchParamsState?.search?.filters?.length);
        setDisableSubmitFilterButton(true);
    };

    const handleClearFilters = () => {
        setTableLoading(true);
        setDisableClearFilterButton(true);
        setDisableSubmitFilterButton(true);
        const newSearchParamsState = { ...searchParams, search: { ...searchParams?.search, filters: [] } };
        if (searchParams.search?.filters?.length) {
            const filterKeys = searchParams.search.filters.map((filterItem) => filterItem.field);
            removeParam(filterKeys, true);
        }
        setSearchParams(newSearchParamsState);
        fetchData(newSearchParamsState);
    };

    const handleFilterFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSubmitFilters();
    };

    const tableHeader = (
        <div className={style.dashboardTableHeader}>
            <div className={style.dashboardTableHeaderRowPerPage}>
                <span>Show Rows</span>
                <Dropdown
                    optionLabel="label"
                    optionValue="value"
                    value={searchParams?.search?.per_page}
                    options={ROW_PER_PAGE_SIZES}
                    onChange={(e) => handleTablePerPage(e.value)}
                />
            </div>
            {hasFilterPermission && Boolean(tableFilters.length) && (
                <div className={classNames(style.dashboardTableHeaderButton, showFilters ? style.active : '')} onClick={handleToggleFilter}>
                    <FilterIcon />
                    Filter
                </div>
            )}
            {Boolean(customColumns.length) && (
                <div
                    className={classNames(style.dashboardTableHeaderButton, showCustomize ? style.active : '')}
                    onClick={handleToggleCustomize}
                    color="quaternary"
                >
                    <CustomizeIcon />
                    Customize
                </div>
            )}
        </div>
    );

    const tableFilter = (
        <div className={classNames(style.dashboardTableFilter, showFilters ? style.active : '')}>
            <div className={style.dashboardTableFilterHeader}>
                <span>Filter</span>
                <div>
                    {tableFilters
                        .filter((checkboxFilter) => checkboxFilter.type === FILTER_TYPES.CHECKBOX)
                        .map((checkboxFilter, index) => {
                            const defaultValue = searchParams?.search?.filters?.find((filter) => filter.field === checkboxFilter.name)?.value || '';
                            return (
                                <CheckboxFilter
                                    type={FILTER_TYPES.CHECKBOX}
                                    key={index}
                                    defaultValue={defaultValue}
                                    onChange={(e) => onFilter(e.target.name, e.value, true)}
                                    name={checkboxFilter.name}
                                    label={checkboxFilter.label}
                                />
                            );
                        })}
                </div>
            </div>
            <div className={style.dashboardTableFilterContent}>
                <form className="grid" onSubmit={handleFilterFormSubmit}>
                    {tableFilters
                        .filter((filter) => filter.type !== FILTER_TYPES.CHECKBOX)
                        .map((filter, index) => {
                            const { type, ...filterData } = filter;
                            return (
                                <div key={index} className="col-12 md:col-6 lg:col-3">
                                    {renderFilterItem(type, filterData as ITableBaseFilterData<FILTER_TYPES>)}
                                </div>
                            );
                        })}
                    <div className="col">
                        <Button color="quaternary" type="submit" disabled={Boolean(disableSubmitFilterButton)} onClick={handleSubmitFilters}>
                            Search
                        </Button>
                        <Button onClick={handleClearFilters} disabled={disableClearFilterButton}>
                            Clear
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );

    const tableCustomize = (
        <div className={style.dashboardTableCustomize}>
            <div className={style.dashboardTableCustomizeHeader}>
                <span>Choose Up To 10 Items</span>
                <p>Add, delete and sort metrics just how you need it</p>
            </div>
            <div className={style.dashboardTableCustomizeItems}>
                {customColumns.map((item) => (
                    <div
                        key={item.field}
                        className={classNames(style.dashboardTableCustomizeItem, item.active ? style.active : '')}
                        onClick={() => handleToggleColumn(item.field)}
                    >
                        {item.header}
                        <DotsIcon />
                    </div>
                ))}
            </div>
        </div>
    );

    const tableFooter = (
        <div className={style.dashboardTableFooter}>
            <div className={style.dashboardTableFooterInfo}>{pageReport}</div>
            {hasPaginator && (
                <Paginator
                    className={style.dashboardTableFooterPaginator}
                    rows={searchParams?.search?.per_page}
                    first={firstRow}
                    totalRecords={totalRows}
                    onPageChange={onPageChange}
                />
            )}
        </div>
    );

    const activeColumns = customColumns.filter(({ active }) => active).map(({ field }) => field);
    const customColumnsFields = customColumns.map(({ field }) => field);
    const otherColumns = columns.filter(({ field }) => !customColumnsFields.includes(field)).map(({ field }) => field);
    const showColumns = [...activeColumns, ...otherColumns];

    const makeColumns = columns
        .filter((column) => showColumns.includes(column.field))
        .map((column, index) => {
            return (
                <Column
                    frozen={column.frozen}
                    alignFrozen={column.alignFrozen}
                    key={column.field}
                    field={column.field}
                    sortable={column.sortable}
                    sortField={column.field}
                    header={column.header}
                    body={
                        cellRender[column.field]
                            ? (props) => {
                                  const Component: (data: T & { setFields: Dispatch<SetStateAction<T[]>> }) => JSX.Element = cellRender[column.field];
                                  return <Component {...props} setFields={setTableData} />;
                              }
                            : undefined
                    }
                ></Column>
            );
        });

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDisableSubmitFilterButton(Boolean(!searchParams?.search?.filters?.length && disableClearFilterButton));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams?.search?.filters]);

    useEffect(() => {
        document.body.style.cursor = isScrolling ? 'grabbing' : 'default';
    }, [isScrolling]);

    useEffect(() => {
        document.getElementById('dashboardContent')?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    if (initLoading) return <InitLoading />;
    if (!initLoading && !tableData) return <h5>Oops! Something Went Wrong!</h5>;

    return (
        <div className={classNames(style.dashboardTable, tableLoading ? style.dashboardTableLoading : '')}>
            {tableHeader}
            {hasFilterPermission && Boolean(tableFilters.length) && tableFilter}
            {showCustomize && tableCustomize}
            <div ref={htmlRef} {...handlers} className={style.dashboardTableContent}>
                <DataTable multiSortMeta={multiSortMeta} scrollDirection="both" value={tableData} sortMode="multiple" removableSort onSort={onSort}>
                    {[...makeColumns, ...(suffixCols ? [suffixCols(tableData, setTableData)] : [])]}
                </DataTable>
            </div>
            <>{tableFooter}</>
        </div>
    );
}

export default Table;
